import React, { useState } from "react";

import "./admin.css";
import { Tabs } from "antd";
import Users from "./users.js";
import CustomSearch from "./customSearch";
import GeneralResourceManagement from "./generalResources";
import CoreResourceManagement from "./contentManagement";
import WebResources from "./webResources.js";

export default function SuperAdmin() {
  const [state, setState] = useState({
    activeTab: "users",
  });

  return (
    <div className="wrapper admin">
      <div className="header">
        <h1>Admin</h1>
      </div>

      <Tabs
        value={state.activeTab}
        onChange={(activeTab) => setState({ ...state, activeTab })}
      >
        <Tabs.TabPane tab="Users" key="users">
          <Users />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Core Resources"
          key="contentMangement"
        >
          <CoreResourceManagement />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="General Resources"
          key="otherResources"
        >
          <GeneralResourceManagement />
        </Tabs.TabPane>

        <Tabs.TabPane
          tab="Website Resources"
          key="websiteResources"
        >
          <WebResources />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Invitations"
          key="invitations"
        ></Tabs.TabPane>
        <Tabs.TabPane
          tab="Custom Search"
          key="custom_search"
        >
          <CustomSearch />
        </Tabs.TabPane>
        
      </Tabs>
    </div>
  );
}
