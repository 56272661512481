import React from "react";
import "./App.css";
import { ConfigProvider, Spin } from "antd";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import LoginSignupPage from "./pages/signInUp";
import CustomHeader from "./components/header";
import Standards from "./pages/standards";
import Subtitles from "./pages/subtitles";
import Footer from "./components/footer.jsx";
import PrivacyPolicy from "./legal/PrivacyPolicy.jsx";
import Contact from "./communication/Contact";
import TermsService from "./legal/TermsService";
import Documents from "./pages/documents";
import SearchView from "./pages/search";
import ProjectEditCreate from "./pages/project/projectEdit";
import Projects from "./pages/project/Projects";
import Help from "./communication/Help";
import Payment from "./pages/subscription";
import PublicHeader from "./components/publicHeader";
import Favorites from "./pages/favourite";
import ChatBotPage from "./pages/chat";
import { useAuth, ProviderAuth } from "./authContext";
import ForgotPasswordConfirm from "./pages/signInUp/confirmForgotPassword";
import ForgotPassword from "./pages/signInUp/forgotPassword";
import SuperAdmin from "./pages/admin";
import FinishInvite from "./pages/invite/finishInvite";
import moment from "moment";
import UserArticles from "./pages/userDocs/index.js";
import SubtitlesDetailView from "./pages/subtitleView";
import ChatV2 from "./pages/chatV2/index.jsx";
import ChatDetailV3 from "./pages/chatV3Detail/index.jsx";
import LandingPage from "./pages/landing/index.js";
import SubtitlesDetailViewV2 from "./pages/subtitleViewV2/index.jsx";
import DMCA from "./legal/dmca.jsx";
import { Paywall } from "./components/paywall.jsx";
import PaymentStatus from "./pages/payment/success.js";

const GenLayout = ({ children,  }) => {
  const { user, hasActiveSubscription } = useAuth();

  useEffect(() => {
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "h9jjsuq9",
      name: user.name || user.username, // Full name
      email: user.email, // Email address
      // Signup date as a Unix timestamp
      created_at: moment(user.createdAt).unix(),
    });
  }, [user]);
  const location = useLocation();
  const isHome = location.pathname === "/";
  const isLanding = location.pathname === "/landing";

  return isLanding || isHome || isLanding ? (
    <Outlet />
  ) : isHome ? (
    <>
       <PublicHeader />
      <div
        id="public-detail"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Outlet />
      </div>
      <Footer
        isLanding={true}
        style={{
          marginTop: "auto",
        }}
      />
    </>
  ) : (
    <div
      // style={{
      //   height: "100vh",
      //   overflow: "hidden",

      // }}
      className="h-screen w-screen overflow-clip"
    >
       <CustomHeader simple={!hasActiveSubscription()} />
      <div
        id="detail"
        style={{
          height: "calc(100vh - 64px - 64px - 2px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        className="w-screen"
      >
        <Outlet />
      </div>
      <Footer isAuth={true} />
    </div>
  );
};

const GenPublicLayout = ({ children }) => {
  const location = useLocation();
  const isLoginSignUp =
    location.pathname === "/signin" || location.pathname === "/signup";
  const isHome = location.pathname === "/";
  const isLanding = location.pathname === "/landing";

  return (isLanding || isLoginSignUp || isHome) ?  <Outlet />: (
    <>
      <PublicHeader />
      <div
        id="public-detail"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Outlet />
      </div>
      <Footer
        isLanding={isHome}
        style={{
          marginTop: "auto",
        }}
      />
    </>
  );
};

const protectedRouter = createBrowserRouter([
  {
    path: "/",
    element: <GenLayout />,

    children: [
      {
        path: "",
        // element: <Navigate to="/standards" />,
        element: (
          <div>
            {/* <Home /> */}
            <LandingPage />

          </div>
        ),
      },
      {
        path: "standards",
        element: <Standards title={"Standards"} />,
      },
      {
        path: "state-standards",
        element: <Standards title={"State Standards"} category={"state"} />,
      },
      {
        path: "state-standards/:id",
        element: <Subtitles title={"State Standards"} category={"state"} isState={true} />,
      },
      {
        path: "state-subtitle/:id",
        element: <SubtitlesDetailView title={"Subtitles"} isState={true} />,
      },
      {
        path: "state-subtitle/v2/:id",
        element: <SubtitlesDetailViewV2 title={"Subtitles"} isState={true} />,
      },
      {
        path: "standards/:id",
        element: <Subtitles title={"State Subtitles"} />,
      },
      {
        path: "subtitle/:id",
        element: <SubtitlesDetailView title={"Subtitles"} />,
      },
      {
        path: "subtitle/v2/:id",
        element: <SubtitlesDetailViewV2 title={"Subtitles"} />,
      },
      {
        path: "subtitles/:subtitleId",
        element: <Documents title={"Subtitles"} />,
      },

      {
        path: "search/",
        element: <SearchView title={"Search"} />,
      },
      {
        path: "account",
        element: <Payment />,
      },
      {
        path: "subscription-success",
        element: <PaymentStatus />,
      },
      {
        path: "subscription-cancel",
        element: <Payment />,
      },
      {
        path: "favorites",
        element: <Favorites />,
      },
      { path: "terms-service", element: <TermsService /> },
      { path: "privacy-policy", element: <PrivacyPolicy /> },
      { path: "dmca", element: <DMCA /> },

      { path: "contact", element: <Contact /> },
      { path: "use", element: <Help /> },
      { path: "superadmin", element: <SuperAdmin /> },

      { path: "projects/newProject", element: <ProjectEditCreate /> },
      { path: "projects/:projectId", element: <ProjectEditCreate /> },

      { path: "projects", element: <Projects /> },
      {
        path: "question",
        element: <ChatBotPage />,
      },
      {
        path: "chat",
        element: <ChatV2 />,
      },
      // {
      //   path: "chat",
      //   element: <ChatV3 />,
      // },
      {
        path: "chat/:sessionId",
        element: <ChatDetailV3 />,
      },
      {
        path: "articles",
        element: <UserArticles />,
      },
      { path: "landing", element: <LandingPage /> },

      {
        path: "/*",
        element: (
          <div>
            <Navigate to="/standards" />
          </div>
        ),
      },
    ],
  },
  // {
  //   path: "/label/:id",
  //   element: <LabelHome />,
  // },
  // {
  //   path: "/label",
  //   element: <LabelTaskPage />,
  // },

  // {
  //   path: "/dataset/:id",
  //   element: <PubmedDataHome />,
  // },
  // {
  //   path: "/dataset",
  //   element: <PubmedDataSplit />,
  // },
]);

const protectedRouterWithoutSubscription = createBrowserRouter([
  {
    path: "/",
    element: <GenLayout header={false} />,

    children: [
      {
        path: "",
        // element: <Navigate to="/standards" />,
        element: (
          <div>
            {/* <Home /> */}
            <LandingPage />

          </div>
        ),
      },
      {
        path: "paywall",
        element: <Paywall />,
      },
      {
        path: "account",
        element: <Payment />,
      },
      {
        path: "subscription-success",
        element: <PaymentStatus />,
      },
      {
        path: "subscription-cancel",
        element: <Payment />,
      },
      {
        path: "favorites",
        element: <Favorites />,
      },
      { path: "terms-service", element: <TermsService /> },
      { path: "privacy-policy", element: <PrivacyPolicy /> },
      { path: "dmca", element: <DMCA /> },

      { path: "contact", element: <Contact /> },
      { path: "use", element: <Help /> },
      { path: "superadmin", element: <SuperAdmin /> },

      { path: "projects/newProject", element: <ProjectEditCreate /> },
      { path: "projects/:projectId", element: <ProjectEditCreate /> },

      { path: "projects", element: <Projects /> },
      {
        path: "question",
        element: <ChatBotPage />,
      },
   
      // {
      //   path: "chat",
      //   element: <ChatV3 />,
      // },
      {
        path: "chat/:sessionId",
        element: <ChatDetailV3 />,
      },
      {
        path: "articles",
        element: <UserArticles />,
      },
      { path: "landing", element: <LandingPage /> },

      {
        path: "/*",
        element: (
          <div>
            <Navigate to="/paywall" />
          </div>
        ),
      },
    ],
  },

  // },
]);

const baseRouter = createBrowserRouter([
  {
    path: "/",
    element: <GenPublicLayout />,

    children: [
      {
        path: "",
        element: (
          <div>
            {/* <Home /> */}
            <LandingPage />

          </div>
        ),
      },
      {
        path: "signin",
        element: (
          <div>
            <LoginSignupPage isSignup={false} />
          </div>
        ),
      },
      {
        path: "signup",
        element: (
          <div>
            <LoginSignupPage isSignup={true} />
          </div>
        ),
      },
      {
        path: "forgot-password",
        element: (
          <div>
            <ForgotPassword />
          </div>
        ),
      },
      {
        path: "forgot-password-confirm",
        element: (
          <div>
            <ForgotPasswordConfirm />
          </div>
        ),
      },

      {
        path: "complete_invite",
        element: (
          <div>
            <FinishInvite />
          </div>
        ),
      },
      { path: "terms-service", element: <TermsService /> },
      { path: "privacy-policy", element: <PrivacyPolicy /> },
      { path: "landing", element: <LandingPage /> },
      { path: "dmca", element: <DMCA /> },


      {
        path: "*",
        element: (
          <div>
            <Navigate to="/signin" />
          </div>
        ),
      },
    ],
  },
]);

const queryClient = new QueryClient();

const BaseApp = () => {
  const auth = useAuth();

  return (
    <React.StrictMode>
      {auth.loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          
          {auth.isAuthenticated ? (
            <>
              {auth.hasActiveSubscription() ? (
                <RouterProvider router={protectedRouter} />
              ) : (
                <RouterProvider router={protectedRouterWithoutSubscription} />
              )}
            </>
          ) : (
            <RouterProvider router={baseRouter} />
          )}
        </>
      )}
    </React.StrictMode>
  );
};

const App = () => {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ProviderAuth>
          <ConfigProvider
            theme={{
              token: {
                // fontFamily: "Rethink Sans",
                fontFamily: "Geist Variable",
                // fontFamily: "IBM Plex Sans",
                // fontFamily: "Open Sans",
                // colorPrimary : "rgb(92 107 192)"
                colorPrimary: "#00509d",
                colorText: "#394359",
                colorTextSecondary: "#778DA9",
                colorLink: "#00509d",
                colorLinkHover: "#3373b1",
                colorLinkActive: "#00509d",
              },
            }}
          >
            <BaseApp />
          </ConfigProvider>
        </ProviderAuth>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

export default App;
