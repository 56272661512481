import React, { useState } from "react";
import { AdminService } from "../../services/dataService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button, Col, Input, List, message, Modal, Row } from "antd";
import { Link } from "react-router-dom";
import {  PlusOutlined } from "@ant-design/icons";

const WebResources = () => {
  const [state, setState] = useState({
    page: 1,
    searchQuery: "",
    url: "",
    open: false,
  });

  const queryClient = useQueryClient();
  const doc = useQuery(["webResources", state.page, state.searchQuery], () =>
    AdminService.getWebsiteResources({
      page: state.page,
      limit: 10,
      query: state.searchQuery,
    })
  );

  const addResourceMutation = useMutation(
    (url) =>
      AdminService.createWebsiteResource({
        blog_url: url,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["webResources"]);
        setState({ ...state, url: "", open: false });
        message.success("Resource added successfully");
      },
    }
  );

  return (
    <div>
      <Modal
        title="Add Resource"
        open={state.open}
        onOk={() => {}}
        footer={null}
        onCancel={() => setState({ ...state, open: false })}
        destroyOnClose
      >
        <Input
          placeholder="URL"
          value={state.url}
          onChange={(e) => setState({ ...state, url: e.target.value })}
        />
        <Button
          type="primary"
          onClick={() => {
            addResourceMutation.mutate(state.url);
          }}
          className="mt-2"
        >
          Add
        </Button>
      </Modal>
      <Row justify="space-between">
        <Col>
          <Input.Search
            placeholder="Search"
            style={{ width: "400px" }}
            onSearch={(value) => setState({ ...state, searchQuery: value })}
          />
        </Col>
        <Col>
        <Button
            type="text"
            onClick={() => {
              setState({ ...state, open: true });
            }}
          >
            <PlusOutlined /> Add document
          </Button>
        </Col>
      </Row>
      <br />
      <List
        dataSource={doc.data?.resources}
        loading={doc.isLoading}
        pagination={{
          current: state.page,
          pageSize: 10,
        }}
        renderItem={(item) => (
          <List.Item key={item._id}>
            <Link to={item.url} target="_blank">
              {item.title}
            </Link>
            <Button type="text" danger>
              Delete
            </Button>
          </List.Item>
        )}
      />
    </div>
  );
};

export default WebResources;
