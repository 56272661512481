import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export function dateFormat(date, format) {
  dayjs.extend(utc);
  return dayjs(date).format(format || "MMM DD, YYYY");
}

export function isEmail(email) {
  const filter =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return filter.test(email);
}

export function isValidPassword(password) {
  return password.length > 6;
}

