import React from "react";
import { useNavigate } from "react-router-dom";

import "./payments.css";
import { useMutation } from "react-query";
import { Card, Tag, Typography } from "antd";
import { PaymentServices } from "../../services/dataService";
import { useAuth } from "../../authContext";

export default function Payment() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const subEndDate = user.subscription?.end_date;
  const isExpired = subEndDate ? new Date(subEndDate) < new Date() : false;

  const checkoutUrlMutation = useMutation(
    async (planId) => {
      const data = {
        planId,
        email: user.email,
      };
      const res = await PaymentServices.getChargebeeCheckoutLink(data);
      return res.url;
    },
    {
      onSuccess: (data) => {
        window.open(data, "_blank");
      },
    }
  );

  const getCustomerPortal = async () => {
    const res = await PaymentServices.getChargebeePortalLink();
    // Open the portal link in a new tab
    window.open(res.access_url, "_blank");
  };

  const customerPortalMutation = useMutation(
    async () => {
      const res = await PaymentServices.getChargebeePortalLink();
      return res.access_url;
    },
    {
      onSuccess: (data) => {
        window.open(data, "_blank");
      },
    }
  );

  return (
    <div className="wrapper payment">
      <div className="header">
        <h1>Account</h1>
      </div>

      <Card>
        <Typography.Title level={4}>Account Details</Typography.Title>
        <Typography.Text type="secondary">
          Name
          <br />
        </Typography.Text>
        <Typography.Paragraph>
          <b>{user.name}</b>
          <br />
        </Typography.Paragraph>

        <Typography.Text type="secondary">
          Email
          <br />
        </Typography.Text>
        <Typography.Paragraph>
          <b>{user.email}</b>
          <br />
        </Typography.Paragraph>

        <Typography.Text type="secondary">
          Role: {user.is_admin ? <Tag color="blue">Admin</Tag> : null}
          {user.is_superuser ? <Tag color="green">Superuser</Tag> : null}
          {!user.is_admin && !user.is_superuser ? (
            <Tag color="purple">Member</Tag>
          ) : null}
        </Typography.Text>

        {/* <Elements stripe={stripePromise}>
        <PaymentPlans values={values} setValues={setValues} />
        <PaymentSummary values={values} />
        <PaymentForm values={values} setValues={setValues} />
      </Elements> */}

        {!user.subscription && !isExpired ? (
          <div style={{ marginTop: "1rem" }}>
            <Typography.Title level={4}>
              {isExpired
                ? "Your subscription has expired, please select a plan"
                : "Please select a plan"}{" "}
              <a onClick={() => navigate("/paywall")} type="link">
                here
              </a>
            </Typography.Title>
          </div>
        ) : (
          <div style={{ marginTop: "1rem" }}>
            <Typography.Title level={5}>
              You are already subscribed to a plan
            </Typography.Title>
            <Typography.Paragraph>
              Subscription ID: {user.subscription.id}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {" "}
              Please go to the chargebee{" "}
              <a onClick={getCustomerPortal} type="link">
                portal
              </a>{" "}
              to manage your subscription
            </Typography.Paragraph>
          </div>
        )}
      </Card>
    </div>
  );
}