import React, { useState } from "react";
import { Link } from "react-router-dom";
import FavoriteItem from "./FavoriteItem.jsx";
import {Tabs} from "antd";
import "./Favorites.css";
import { useQuery, useQueryClient } from "react-query";
import { FavouriteService } from "../../services/dataService.js";
import PDFDocumentView from "../../components/pdfViewer/index.js";
import { Modal } from "antd";
import { CoreResourceView, GeneralResourceView, BlogView } from "../subtitleViewV2/index.jsx";

export default function Favorites() {
  const [state, setState] = useState({ error: "" });

  const favourites = useQuery(["favouritesList"], () =>
    FavouriteService.getAll({})
  );

  const favouriteMap = useQuery(["favourites"], () =>
    FavouriteService.getAllMap()
  );
  const queryClient = useQueryClient();
  const onChangeFavourite = () => {
    queryClient.invalidateQueries("favourites");
  };

  if (favourites.isLoading) return;

  return (
    <div className="wrapper favorites">
      <Modal
        width={1000}
        title="PDF Viewer"
        open={state.selectItem}
        onCancel={() => setState((prev) => ({ ...prev, selectItem: false }))}
        footer={null}
        destroyOnClose={true}
      >
        <div
          style={{
            height: "80vh",
          }}
        >
          <PDFDocumentView
            id={state.selectItem?.id}
            url={state.selectItem?.url}
            title={state.selectItem?.title}
          />
        </div>
      </Modal>

      <div className="header">
        <h1>Favorites</h1>
      </div>

      <Tabs defaultActiveKey="0">
        <Tabs.TabPane
          tab={
            <>
               Standards-Aligned Best Practices
            </>
          }
          key="0"
        >
          <CoreResourceView
            resources={
              favourites.data?.results?.filter(
                (item) => item.type === "resource"
              ).map(
                item => item.metadata
              ) || []
            }
            favouritesMap={favouriteMap.data || {}}
            favouritesLoading={favouriteMap.isLoading}
            onChangeFavourite={onChangeFavourite}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="General Resources" key="1">
          <GeneralResourceView
            articles={
              favourites.data?.results?.filter(
                (item) => item.type === "article"
              ).map(
                item => item.metadata
              ) || []
            }
            favouritesMap={favouriteMap.data || {}}
            favouritesLoading={favouriteMap.isLoading}
            onChangeFavourite={onChangeFavourite}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Web Resources" key="2">
          <BlogView
            blogs={
              favourites.data?.results?.filter(
                (item) => item.type === "blog"
              ).map(
                item => item.metadata
              ) || []
            }
            favouritesMap={favouriteMap.data || {}}
            favouritesLoading={favouriteMap.isLoading}
            onChangeFavourite={onChangeFavourite}
          />
        </Tabs.TabPane>
      </Tabs>
{/*       
          {favourites.data?.results?.length ? (
        <div className="favorites-list">
          {favourites.data?.results?.map((favorite) => (
            <FavoriteItem
              key={favorite.id}
              item={favorite}
              onSelect={(item) =>
                setState((prev) => ({ ...prev, selectItem: item }))
              }
              //   values={values}
              //   setValues={setValues}
            />
          ))}
        </div>
      ) : (
        <div className="item empty-favorites">
          <p>
            No favorites added. Head over to the{" "}
            <Link to="/standards">Standards</Link> section to add new favorites.
          </p>
        </div>
      )} */}
    </div>
  );
}
