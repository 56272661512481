import React, { useEffect, useState, Fragment } from "react";
import { isEmail } from "../components/functions.jsx";
import { errorMessage } from "./Contact.helpers.jsx";
import { WarningIcon } from "../components/Icons.jsx";
import "./Contact.css";
import { Button, message, Form, Input } from "antd";
import { useMutation } from "react-query";
import { HelperServices } from "../services/dataService.js";

export default function Contact() {
  const [form] = Form.useForm();
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    error: "",
    loading: false,
  });
  // const { user } = useAccount();

  // useEffect(() => {
  //   if (user) setValues({ ...values, name: user.profile.name, email: user.emails[0].address });
  // }, [user]);

  const contactRequest = useMutation(
    (values) => HelperServices.submitContactRequest(values),
    {
      onSuccess: (data) => {
        console.log(data);
        message.success("Message sent successfully");
      },
      onError: (error) => {
        console.log(error);
        message.error("Error sending message");
      },
    }
  );

  const handleSubmit = (values) => {
    contactRequest.mutate(values);
  };

  return (
    <Fragment>
      <main className="wrapper contact">
        <div className="header flex flex-col items-center justify-center">
          <h1>Contact Us</h1>
        </div>

        <div className="form-container">
        <Form
          layout="vertical"
          form={form}
          name="register"
          onFinish={handleSubmit}
          initialValues={{}}
          style={{ maxWidth: 800, margin: "0px auto", marginTop: "1.5rem" }}
          scrollToFirstError
        >
          {/* <p>You are in the right place, feel free to sent us a message and we'll contact you in less than 48hrs.</p> */}

          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please add your name!" }]}
          >
            <Input placeholder="Your name" />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Please add your email!" }]}
          >
            <Input placeholder="Your email" />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone"
            rules={[{ required: true, message: "Please add your phone!" }]}
          >
            <Input placeholder="Your phone" />
          </Form.Item>

          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: true, message: "Please add your message!" }]}
          >
            <Input.TextArea placeholder="Your message" />
          </Form.Item>

          <div className="actions">
            <Button
              loading={contactRequest.isLoading}
              htmlType="submit"
              disabled={values.loading}
              type="primary"
            >
              Send Message
            </Button>

          </div>
        </Form>
        </div>
      </main>
    </Fragment>
  );
}
