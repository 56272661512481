import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import "./admin.css";
import { useMutation, useQuery } from "react-query";
import { SearchService } from "../../services/dataService";
import { Button, Col, Input, Row, Select, Tabs, Typography } from "antd";

const BingSearchItem = ({ item }) => {
  return (
    <div key={item.id} style={{marginBottom: "1.5rem"}}>
      <Link to={item.url} target="_blank">
        <Typography.Title level={5}>
          <a>{item.name}</a>
        </Typography.Title>
      </Link>
      <Typography.Text italic style={{marginRight: "1rem"}}>{item.datePublishedDisplayText}</Typography.Text>
      <span
        dangerouslySetInnerHTML={{ __html: item.snippet }}
      ></span>
    </div>
  );
};

const GoogleSearchItem = ({ item }) => {
  return (
    <div key={item.link} style={{marginBottom: "1.5rem"}}>
      <Link to={item.link} target="_blank">
        <Typography.Title level={5}><a>{item.title}</a></Typography.Title>
      </Link>
      <Typography.Text>{item.snippet}</Typography.Text>
    </div>
  );
};
const CustomSearch = () => {
  const [state, setState] = useState({});
  const keywords = useQuery(
    "keywords",
    () => SearchService.getKeywordSuggestions({}),
    {
      // refetchOnWindowFocus: false,
    }
  );

  const customSearch = useMutation((data) => SearchService.customSearch(data), {
    onSuccess: (data) => {
      console.log(data);
    },
  });

  return (
    <div>
      <Row>
        <Col span={16}>
          <Select
            value={state.keywords}
            mode="tags"
            style={{ width: "100%" }}
            onChange={(value) => setState({ ...state, keywords: value })}
          >
            {keywords.data?.results?.map((keyword) => (
              <Select.Option key={keyword.key} value={keyword.key}>
                {keyword.key}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={8}>
          <Button
            onClick={() =>
              customSearch.mutate({
                keywords: state.keywords.join("|"),
              })
            }
          >
            Search
          </Button>
        </Col>
      </Row>

      <Tabs
        value={state.activeTab}
        onChange={(activeTab) => setState({ ...state, activeTab })}
      >
        <Tabs.TabPane tab="Google" key="google">
          {customSearch.data?.google_results?.map((item) => (
            <GoogleSearchItem item={item} />
          ))}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Bing" key="bing">
          {customSearch.data?.bing_results?.map((item) => (
            <BingSearchItem item={item} />
          ))}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default CustomSearch;
