import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  ArticleService,
  FavouriteService,
  SearchService,
  StandardService,
} from "../../services/dataService.js";
import {
  Button,
  Card,
  Spin,
  Typography,
  Space,
  Row,
  Col,
  Tabs,
  Tag,
  Modal,
  Divider,
  message,
  Empty,
  Badge,
} from "antd";
import PDFDocumentView from "../../components/pdfViewer/index.js";
// import "./subtitles.css";

const ResourceView = ({
  type = "resource",
  resources,
  favouritesLoading,
  favouritesMap,
  onChangeFavourite,
}) => {
  const [selected, setSelected] = React.useState(null);
  const navigate = useNavigate();
  let downloadFile = useMutation(
    (id) => SearchService.getDocument(selected?.id || id),
    {
      enabled: selected,
    }
  );

  let userFile = useMutation(
    (id) => ArticleService.donwloadArticle(selected?.id || id),
    {
      enabled: selected,
    }
  );

  let openPDF = (id) => {
    // Download file and open in PDF viewer
    debugger;
    if (type === "userArticle") {
      userFile.mutateAsync(id).then((res) => {
        // Convert res to blob
        var blob = new Blob([res], { type: "application/pdf" });
        var url = URL.createObjectURL(blob);
        // Open in new tab
        window.open(url, "_blank");
      });
      return;
    } else {
      downloadFile.mutateAsync(id).then((res) => {
        // Convert res to blob
        var blob = new Blob([res], { type: "application/pdf" });
        var url = URL.createObjectURL(blob);
        // Open in new tab
        window.open(url, "_blank");
      });
    }
  };

  const favourite = useMutation(
    (item) =>
      FavouriteService.create({
        type: type === "userArticle" ? "userArticle" : "resource",
        document_id: item.id,

        metadata: item,
      }),
    {
      onSuccess: () => {
        message.success("Added to favourites");
        onChangeFavourite();
      },
    }
  );

  const removeFavourite = useMutation((id) => FavouriteService.delete(id), {
    onSuccess: () => {
      message.success("Removed from favourites");
      onChangeFavourite();
    },
  });

  const openItem = (item) => {
    navigate("/projects/newProject", {
      state: {
        title: item.openai_summary?.title || item.title,
        summary: item.openai_summary?.abstract,
      },
    });
  };

  return (
    <>
      <Modal
        visible={selected}
        footer={null}
        onCancel={() => setSelected(null)}
        width={"90vw"}
        destroyOnClose={true}
      >
        <Row gutter={[12, 12]} style={{ height: "90vh" }}>
          <Col span={14}>
            <div
              style={{
                display: "flex",
                height: "100%",
              }}
            >
              {selected ? (
                <PDFDocumentView
                  title={
                    selected?.openai_summary?.title ||
                    selected?.title ||
                    selected?.name ||
                    selected?.file_name
                  }
                  file={
                    selected?.name ||
                    selected?.title ||
                    selected?.file_name ||
                    selected?.file_name
                  }
                  dataLoader={
                    type === "userArticle"
                      ? userFile.mutateAsync
                      : downloadFile.mutateAsync
                  }
                />
              ) : null}
              <Divider
                type="vertical"
                style={{
                  height: "100%",
                }}
              />
            </div>
          </Col>
          <Col span={10}>
            <Typography.Title
              level={4}
              style={{
                color: "var(--primary-400)",
              }}
            >
              {selected?.title ||
                selected?.name ||
                selected?.title ||
                selected?.file_name}
            </Typography.Title>
            <Typography.Paragraph>
              {selected?.abstract}
            </Typography.Paragraph>

            {/* {selected?.summary?.map((item) => (
              <>
                <Typography.Title
                  level={5}
                  style={{
                    color: "var(--primary-300)",
                  }}
                >
                  {item.standard_title}
                </Typography.Title>
                <Typography.Paragraph>{item.summary}</Typography.Paragraph>
              </>
            ))} */}
            {selected?.summary && (
              <Typography.Paragraph>{selected?.summary}</Typography.Paragraph>
            )}
          </Col>
        </Row>
      </Modal>

      <Row gutter={[12, 12]}>
        {resources?.map((item) => (
          <Col span={24} key={item.id}>
            <Card key={item.id}>
              <div
                style={{
                  marginBottom: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography.Title
                      level={5}
                      style={{
                        color: "var(--primary-400)",
                      }}
                    >
                      {item.openai_summary?.title || item.name || item.title}
                    </Typography.Title>

                    {item.is_processed ? (
                      <div className="author-list">
                        <i className="fa-regular fa-user"></i>
                        <>
                          {item?.authors
                            .slice(0, 3)
                            .map((item) => (
                              <Tag color="blue" className="chip">
                                {item}
                              </Tag>
                            ))}
                        </>
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <a>
                      <i
                        className={
                          !favouritesLoading && favouritesMap[item.id]
                            ? "fa-solid fa-star star-icon"
                            : "fa-regular fa-star star-icon"
                        }
                        style={{
                          fontSize: "18px",
                          marginTop: "8px",
                        }}
                        onClick={() => {
                          if (favouritesMap[item.id]) {
                            removeFavourite.mutate(favouritesMap[item.id]);
                          } else {
                            favourite.mutate(item);
                          }
                        }}
                      ></i>
                    </a>
                  </div>
                </div>
                {/* {item.is_processed ? (
                  <div style={{ padding: "8px 0px" }}>
                    {item.openai_summary?.standard_summaries?.length > 0 &&
                      item.openai_summary?.standard_summaries
                        .slice(0, 3)
                        .map((item, idx) => (
                          <Tag className="primary-chip" key={idx}>
                            {item.standard_title}
                          </Tag>
                        ))}
                  </div>
                ) : null} */}
              </div>
              {item.is_processed ? (
                <Typography.Paragraph>
                  {item?.abstract}
                </Typography.Paragraph>
              ) : (
                <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",

                }}
                >
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{ height: 60 }}
                  description={<Badge status="processing" text="Processing" />}
                  style={{
                    marginBottom: "8px",
                  }}
                >
                  {/* <Button type="primary">Create Now</Button> */}
                </Empty>
                </div>
              )}

              <Space direction="horizontal" wrap>
                <Button
                  onClick={() => openPDF(item.id)}
                  size="small"
                  type="text"
                  className="text-action-btn"
                >
                  <i className="fa-regular fa-file-pdf button-icon"></i>
                  View PDF
                </Button>
                <Button
                  // onClick={() => window.open(item.pdf_link, "_blank")}
                  onClick={() => openItem(item)}
                  size="small"
                  type="text"
                  className="text-action-btn"
                >
                  <i className="fa-regular fa-pen-to-square  button-icon"></i>
                  Open in Item
                </Button>
                <Button
                  onClick={() => setSelected(item)}
                  size="small"
                  type="text"
                  className="text-action-btn"
                >
                  <i className="fa-regular fa-rectangle-list button-icon"></i>
                  View Summary
                </Button>
              </Space>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

const ArticleView = ({
  articles,
  favouritesLoading,
  favouritesMap,
  onChangeFavourite,
}) => {
  const navigate = useNavigate();
  const openItem = (item) => {
    navigate("/projects/newProject", {
      state: {
        title: item.title,
        summary: item.abstract,
      },
    });
  };
  const favourite = useMutation(
    (item) =>
      FavouriteService.create({
        type: "article",
        document_id: item.custom_id,

        metadata: item,
      }),
    {
      onSuccess: () => {
        message.success("Added to favourites");
        onChangeFavourite();
      },
    }
  );

  const removeFavourite = useMutation((id) => FavouriteService.delete(id), {
    onSuccess: () => {
      message.success("Removed from favourites");
      onChangeFavourite();
    },
  });

  return (
    <Row gutter={[12, 12]}>
      {articles?.map((item) => (
        <Col span={24} key={item.id || item.custom_id}>
          <Card>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <div>
                <Typography.Title
                  level={5}
                  style={{
                    color: "var(--primary-400)",
                  }}
                >
                  {item.title}
                </Typography.Title>
                {/* <Tag color="blue" className="chip">{item.source}</Tag> */}
                <Tag color="blue" className="chip">
                  {" "}
                  <i className="fa-regular fa-calendar"></i>
                  {item.date}
                </Tag>
                <Tag color="blue" className="chip">
                  <i className="fa-regular fa-user"></i>{" "}
                  {item.authors.join(", ")}
                </Tag>
                <Tag color="blue" className="chip">
                  {item.has_full_access ? (
                    <i className="fa-solid fa-lock-open"></i>
                  ) : (
                    <i className="fa-solid fa-lock"></i>
                  )}
                  {item.has_full_access ? "Full Access" : "Limited Access"}
                </Tag>
              </div>
              <div>
                <a>
                  <i
                    // className="fa-regular fa-star"
                    className={
                      !favouritesLoading && favouritesMap[item.id]
                        ? "fa-solid fa-star star-icon"
                        : "fa-regular fa-star star-icon"
                    }
                    onClick={
                      !favouritesLoading
                        ? () => {
                            if (favouritesMap[item.id]) {
                              removeFavourite.mutate(favouritesMap[item.id]);
                            } else {
                              favourite.mutate(item);
                            }
                          }
                        : null
                    }
                    style={{
                      fontSize: "18px",
                    }}
                  ></i>
                </a>
              </div>
            </div>

            <Typography.Paragraph>{item.abstract}</Typography.Paragraph>

            <Space direction="horizontal">
              <Button
                onClick={() => window.open(item.pdf_link, "_blank")}
                size="small"
                type="text"
                className="text-action-btn"
              >
                <i className="fa-regular fa-file-pdf button-icon"></i>
                PDF Link
              </Button>
              <Button
                // onClick={() => window.open(item.pdf_link, "_blank")}
                onClick={() => openItem(item)}
                size="small"
                type="text"
                className="text-action-btn"
              >
                <i className="fa-regular fa-pen-to-square  button-icon"></i>
                Open in Item
              </Button>
            </Space>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

const BlogView = ({
  blogs,
  favouritesLoading,
  favouritesMap,
  onChangeFavourite,
}) => {
  const navigate = useNavigate();

  const openItem = (item) => {
    navigate("/projects/newProject", {
      state: {
        title: item.title,
        summary: item.description || item.og_description,
      },
    });
  };
  const favourite = useMutation(
    (item) =>
      FavouriteService.create({
        type: "blog",
        document_id: item.id,

        metadata: item,
      }),
    {
      onSuccess: () => {
        message.success("Added to favourites");
        onChangeFavourite();
      },
    }
  );

  const removeFavourite = useMutation((id) => FavouriteService.delete(id), {
    onSuccess: () => {
      message.success("Removed from favourites");
      onChangeFavourite();
    },
  });
  console.log(blogs);
  return (
    <Row gutter={[12, 12]}>
      {blogs?.map((item) => {
        let authors = item.authors?.filter((item) => item && item !== "");

        return (
          <Col span={24} key={item.id}>
            <Card>
              <div
              className="blog-card"
                style={{
                  display: "flex",
                  //   alignItems: "center",
                  // justifyContent: "space-between",
                }}
              >
                <img
                  src={item.image || item.og_image}
                  style={{ width: "200px", objectFit: "cover" }}
                />
                <div
                  style={{
                    flex: 1,
                    // marginLeft: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "1rem",
                    }}
                  >
                    <div>
                      <Typography.Title level={5}>
                        {item.title}
                      </Typography.Title>
                      {/* <Tag color="blue" className="chip">{item.source}</Tag> */}
                      {item.date ? (
                        <Tag color="blue" className="chip">
                          {" "}
                          <i className="fa-regular fa-calendar"></i>
                          {item.date}
                        </Tag>
                      ) : null}
                      {authors.length > 0 ? (
                        <Tag color="blue" className="chip">
                          <i className="fa-regular fa-user"></i>{" "}
                          {authors.join(", ")}
                        </Tag>
                      ) : null}
                    </div>
                    <div>
                      <a>
                        <i
                          className={
                            !favouritesLoading && favouritesMap[item.id]
                              ? "fa-solid fa-star star-icon"
                              : "fa-regular fa-star star-icon"
                          }
                          onClick={
                            !favouritesLoading
                              ? () => {
                                  if (favouritesMap[item.id]) {
                                    removeFavourite.mutate(
                                      favouritesMap[item.id]
                                    );
                                  } else {
                                    favourite.mutate(item);
                                  }
                                }
                              : null
                          }
                          style={{
                            fontSize: "18px",
                          }}
                        ></i>
                      </a>
                    </div>
                  </div>

                  <Typography.Paragraph>
                    {item.description || item.og_description}
                  </Typography.Paragraph>

                  <Space direction="horizontal">
                    <Button
                      onClick={() => window.open(item.link, "_blank")}
                      size="small"
                      type="text"
                      className="text-action-btn"
                    >
                      <i className="fa-solid fa-link button-icon"></i>
                      Go to Link
                    </Button>
                    <Button
                      // onClick={() => window.open(item.pdf_link, "_blank")}
                      onClick={() => openItem(item)}
                      size="small"
                      type="text"
                      className="text-action-btn"
                    >
                      <i class="fa-regular fa-pen-to-square  button-icon"></i>
                      Open in Item
                    </Button>
                  </Space>
                </div>
              </div>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

const SubtitlesDetailView = ({ status, setStatus, isState }) => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const subtitleDetail = useQuery("subtitles", () =>
    StandardService.getSubtitle(id)
  );

  const favourite = useQuery(["favourites"], () =>
    FavouriteService.getAllMap()
  );
  const onChangeFavourite = () => {
    queryClient.invalidateQueries("favourites");
  };

  const ragSearch = useQuery(
    ["ragsearch", subtitleDetail?.data?.content],
    () =>
      SearchService.ragSearch({
        search: subtitleDetail?.data?.content,
        // page: state.page,
      }),
    {
      enabled: subtitleDetail?.data?.content ? true : false,
      staleTime: Infinity,
    }
  );

  return (
    <div className="wrapper subtitles">
      <div className="header">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <Col flex="auto">
            <div className="breadcrumbs">
              <span onClick={() => navigate("/standards")}>Standards</span> /{" "}
              <span
                onClick={() =>
                {
                  isState ? 
                  navigate("/state-standards/" + subtitleDetail.data?.standard?.id)
                  :
                  navigate("/standards/" + subtitleDetail.data?.standard?.id)
                }
                }
              >
                {subtitleDetail.data?.standard?.title}
              </span>
            </div>
            <Typography.Title level={4}>
              {subtitleDetail.data?.content}
            </Typography.Title>
          </Col>
        </div>
      </div>

      <Tabs defaultActiveKey="0">
        <Tabs.TabPane
          // tab={
          //   <>
          //     <span className="pro-badge">Pro</span> Resources
          //   </>
          // }
          tab={"Standards-Aligned Best Practices"}
          key="0"
        >
          <ResourceView
            resources={ragSearch.data?.resources}
            favouritesMap={favourite.data}
            favouritesLoading={favourite.isLoading}
            onChangeFavourite={onChangeFavourite}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="General Resources" key="1">
          <ArticleView
            articles={ragSearch.data?.articles}
            favouritesMap={favourite.data}
            favouritesLoading={favourite.isLoading}
            onChangeFavourite={onChangeFavourite}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Web Resources" key="2">
          <BlogView
            blogs={ragSearch.data?.blogs}
            favouritesMap={favourite.data}
            favouritesLoading={favourite.isLoading}
            onChangeFavourite={onChangeFavourite}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Your Articles" key="3">
          <ResourceView
            type="userArticle"
            resources={ragSearch.data?.user_articles || []}
            favouritesMap={favourite.data}
            favouritesLoading={favourite.isLoading}
            onChangeFavourite={onChangeFavourite}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

// export { ResourceView, ArticleView, BlogView };
export default SubtitlesDetailView;
