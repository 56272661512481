import React from "react";
import { useMutation } from "react-query";
import { AuthService } from "../../services/dataService.js";
import { Button, Checkbox, Form, Input } from "antd";
import "./index.css";

const CreateInvite = (props) => {
  const createInvite = useMutation((data) => AuthService.createInvite(data), {
    onSuccess: (data) => {
      props.onSuccess()
      props.onClose && props.onClose();
    },
    onError: (error) => {
    //   message.error("Something went wrong");
    props.onError()
    },
  });
  const onFinish = (values) => {
    createInvite.mutate({
      email: values.email,
    });
  };

  const [form] = Form.useForm();

  return (
    <div className="create-invite">
      <div
        className="wrapper"
        style={{ maxWidth: "500px", margin: "0px auto", padding: "0px 25px" }}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{}}
          onFinish={onFinish}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label=""
            name="is_beta_user"
            valuePropName="checked"
          >
            <Checkbox>
              <span>Check this box if the user is a beta user</span>
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              loading={createInvite.isLoading}
              type="primary"
              htmlType="submit"
              style={{ marginRight: "20px" }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreateInvite;
