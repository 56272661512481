import React, { Fragment, useState } from "react";
import { Form, Row, Input, DatePicker, Button, Checkbox } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { dateFormat } from "../../../components/functions";

function MilestonePreview({ form, milestone, field }) {
  return (
    <Fragment>
      <div className="preview-label">Milestone</div>
      <div className="preview-value">{milestone.milestone}</div>
      <div className="preview-label">Who's Responsible</div>
      <div className="preview-value">{milestone.responsible}</div>
      <div className="preview-label">Deadline</div>
      <div className="preview-value">
        {dateFormat(milestone.timeline, "MMMM DD, YYYY")}
      </div>
      <Form.Item
        valuePropName="checked"
        {...field}
        name={[field.name, "completed"]}
        label=""
        fieldKey={[field.fieldKey, "completed"]}
      >
        <Checkbox>Completed</Checkbox>
      </Form.Item>
    </Fragment>
  );
}

export const ProjectMilestones = ({ form, fields, add, remove }) => {
  const [state, setState] = useState({
    updateItemIdx: null,
  });

  const addNewItem = () => {
    add({});
    setState({ ...state, updateItemIdx: fields.length });
  };

  return (
    <div style={{marginTop: "1rem"}}>
      <h4>Project Milestone</h4>
      {fields.map((field, index) => (
        <div key={index} className="project-outcome-form">
          <Row
            // style={{ marginBottom: 8 }}
            align="middle"
          >
            <div style={{ color: "grey", marginBottom: ".5rem" }}>
              Milestone #{index + 1}
            </div>
            <MinusCircleOutlined
              style={{ color: "red", marginLeft: "auto" }}
              onClick={() => {
                remove(field.name);
              }}
            />
          </Row>
          {/* <Row key={field.key} style={{ marginBottom: 8 }} align="middle"> */}
          {index == state.updateItemIdx ? (
            <>
              <Form.Item
                {...field}
                name={[field.name, "milestone"]}
                label="Milestone"
                fieldKey={[field.fieldKey, "name"]}
                rules={[{ required: true, message: "Please input milestone!" }]}
              >
                <Input placeholder="e.g. Build new alternatives..." />
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, "responsible"]}
                label="Who's Responsible"
                fieldKey={[field.fieldKey, "responsible"]}
                rules={[
                  { required: true, message: "Please input responsible!" },
                ]}
              >
                <Input placeholder="e.g. John Doe" />
              </Form.Item>

              <Form.Item
                {...field}
                name={[field.name, "deadline"]}
                label="Deadline"
                fieldKey={[field.fieldKey, "timeline"]}
                rules={[{ required: true, message: "Please input deadline!" }]}
              >
                <DatePicker />
              </Form.Item>

              <Button
                size="small"
                onClick={() => {
                  // Validate fields to show error message

                  form
                    .validateFields(["milestones"])
                    .then((values) => {
                      // debugger
                      console.log(values);
                      setState((prev) => ({ ...prev, updateItemIdx: null }));
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                Done
              </Button>
            </>
          ) : (
            <div key={index} className="project-outcome-preview">
              <MilestonePreview
                milestone={form.getFieldValue(["milestones", field.name])}
                field={field}
              />
              {state.updateItemIdx == null && (
                <Button
                  size="small"
                  onClick={() =>
                    setState((prev) => ({ ...prev, updateItemIdx: index }))
                  }
                >
                  Edit
                </Button>
              )}
            </div>
          )}
        </div>
      ))}
      {state.updateItemIdx == null && (
        <Button
          type="dashed"
          onClick={addNewItem}
          block
          icon={<PlusOutlined />}
        >
          Add Milestone
        </Button>
      )}
    </div>
  );
};
