import React from "react";
import { Row, Col, Button, Layout, Space } from "antd";
import { useNavigate } from "react-router-dom";
const { Header } = Layout;

const PublicHeader = () => {
  const navigate = useNavigate();
  return (
    <Header className="public-header">
      <Row style={{ maxWidth: "1200px" }} align="middle">
        <Col span={4} style={{ height: "50px" }}>
          <img
            src="/logo.png"
            alt="School Leader's Advantage | Logo"
            style={{ height: "50px", margin: "auto 0px" }}
          />
        </Col>
        <Col span={20}>
          <div style={{ float: "right" }} className="nav-menu">
            <Space>
              <Button
                //   type="primary"
                size="large"
                ghost
                onClick={() => navigate("/signin")}
                //   type={location.pathname == "/signin" ? "link" : "text"}
              >
                Sign In
              </Button>
              {/* <Button
                type="primary"
                size="large"
                onClick={() => navigate("/signup")}
                //   type={location.pathname.match(/\/signup/) ? "link" : "text"}
              >
                Sign Up
              </Button> */}
            </Space>

            {/* <Button
              onClick={() => navigate("/help")}
              type={location.pathname.match(/\/help/) ? "link" : "text"}
            >
              Help
            </Button> */}
          </div>

          <div className="hamburg-menu">
            {/* <Popover
              arrow={false}
              placement="bottomLeft"
              content={
                <div className="popover-content">
                  <Space
                    direction="vertical"
                    split={<Divider style={{ margin: "0px" }} />}
                  >
                    <Button
                      onClick={() => navigate("/signin")}
                      type={location.pathname == "/signin" ? "link" : "text"}
                    >
                      Sign In
                    </Button>
                    <Button
                      onClick={() => navigate("/signup")}
                      type={
                        location.pathname.match(/\/signup/) ? "link" : "text"
                      }
                    >
                      Sign Up
                    </Button>

                    
                  </Space>
                </div>
              }
            >
              <MenuOutlined
                style={{
                  color: "white",
                  fontSize : "18px", 
                  marginRight: "0.5rem"
                }}
              />
            </Popover> */}
            <Button
              ghost
              onClick={() => navigate("/signin")}
              // type={location.pathname == "/signin" ? "link" : "text"}
              type="primary"
            >
              Sign In
            </Button>
          </div>
        </Col>
      </Row>
      {/* <div className="logo" /> */}
      {/* <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']} items={items1} /> */}
    </Header>
  );
};

export default PublicHeader;
